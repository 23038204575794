export const buildBookingApprovalUrl = ({
  approvalId,
  userId,
  baseUrl,
}: {
  approvalId: string;
  userId: string;
  baseUrl: string;
}) => {
  return `${baseUrl}/booking-approvals/${approvalId}?userId=${userId}`;
};
