import { Box } from '@chakra-ui/react';
import {
  CreateDeliveryItem,
  CreateDeliveryStop,
  CreateOrderMultiCollection,
  MerchantAccount,
} from '@tradeaze-packages/schemas';
import { UseFormReturn } from 'react-hook-form';
import { AddMarkerFn, RemoveMarkerFn } from '../../../map';
import {
  BookingApprovalAlert,
  BookingInformationSection,
  CompleteOrderButton,
  DeliveryDetailsSection,
  DropOffSection,
} from '../../order-form-sections';

type MultiCollectionAddDeliveryProps = {
  merchantAccount?: MerchantAccount;
  dropOffForm: UseFormReturn<CreateDeliveryStop>;
  addDeliveryMarker: AddMarkerFn;
  removeDeliveryMarker: RemoveMarkerFn;
  isDropOffComplete: boolean;
  isVehicleDetailsComplete: boolean;
  handleSubmit: () => Promise<void>;
  hidePriorityDelivery: boolean;
  isAnyStopOverThreshold: boolean;
  isDeliveryPriceLoading: boolean;
  deliveryPriceErrorMessage: string | null;
  itemDetails: CreateDeliveryItem;
  isAdmin?: boolean;
  isLoading: boolean;
  isBookingInformationComplete: boolean;
  initialDate?: Date;
  showExpanded: boolean;
  restrictedEditing: boolean;
  stopId: number;
  currentOrder: Partial<CreateOrderMultiCollection>;
  isBookingApprovalRequired: boolean;
};

export const MultiCollectionAddDelivery = ({
  merchantAccount,
  dropOffForm,
  addDeliveryMarker,
  removeDeliveryMarker,
  isDropOffComplete,
  isVehicleDetailsComplete,
  handleSubmit,
  hidePriorityDelivery,
  isAnyStopOverThreshold,
  isDeliveryPriceLoading,
  deliveryPriceErrorMessage,
  itemDetails,
  isAdmin = false,
  isLoading,
  isBookingInformationComplete,
  initialDate,
  showExpanded,
  restrictedEditing,
  stopId,
  currentOrder,
  isBookingApprovalRequired,
}: MultiCollectionAddDeliveryProps) => {
  return (
    <Box display="flex" flexDirection="column" gap={10} mt={4}>
      <DropOffSection
        sectionName="Drop Off"
        merchantAccount={merchantAccount}
        form={dropOffForm}
        isSectionComplete={isDropOffComplete}
        addMarker={addDeliveryMarker}
        removeMarker={removeDeliveryMarker}
        showExpanded={showExpanded}
        restrictedEditing={restrictedEditing}
        stopId={stopId}
        cypressTestId="dropoff-section"
      />
      <DeliveryDetailsSection
        sectionName="Delivery Details"
        merchantAccount={merchantAccount}
        initialDate={initialDate}
        hidePriorityDelivery={hidePriorityDelivery}
        isAdmin={isAdmin}
        isAnyStopOverThreshold={isAnyStopOverThreshold}
        isDeliveryPriceLoading={isDeliveryPriceLoading}
        deliveryPriceErrorMessage={deliveryPriceErrorMessage}
        itemDetails={itemDetails}
        isSectionComplete={isVehicleDetailsComplete}
        hideSection={restrictedEditing}
      />
      <BookingInformationSection
        sectionName="Booking Information"
        isSectionComplete={isBookingInformationComplete}
      />
      
      {isBookingApprovalRequired && <BookingApprovalAlert />}

      <CompleteOrderButton
        isLoading={isLoading}
        handleSubmit={handleSubmit}
        isUpdatingOrder={showExpanded}
        currentOrder={currentOrder}
        label={
          showExpanded
            ? 'Update Multi-Collection Order'
            : 'Create Multi-Collection Order'
        }
        orderType="MULTI_COLLECTION"
      />
    </Box>
  );
};
