import React from 'react';
import { Box, Divider, Text, VStack, HStack } from '@chakra-ui/react';
import { HydratedDelivery, HydratedOrder } from '@tradeaze-packages/schemas';
import { CardBookingApprovalTag, CardEtaTag, CardStatusDescription } from '../components';
import {
  CardAdminMenu,
  CardDeliveryOption,
  CardDeliveryWindow,
  CardDriverInfo,
  CardItemsInfo,
  CardPostcodes,
  CardProgressBar,
  CardTimeRemaining,
  GridCardContainer,
  GridCardContent,
  GridCardOrderReference,
  GridCardTab,
} from './components';
import { useDeliveryCardData } from './hooks';

type GridDeliveryCardProps = {
  delivery: HydratedOrder['deliveries'][number] & { order: HydratedOrder };
  onSelectDelivery?: (delivery?: HydratedDelivery) => void;
  onHoverDelivery?: (deliveryId: string) => void;
  onHoverLeaveDelivery?: () => void;
  onPressGoTo: (deliveryId: string) => void;
  isAdmin: boolean;
  isSelected: boolean;
  isHovered: boolean;
};

// The rerendering of so many cards on the admin dashboard was causing performance issues.
// This memoization function is a way to prevent rerendering of the card when the key props used for displaying data haven't changed.
const shouldRerender = (
  prevProps: GridDeliveryCardProps,
  nextProps: GridDeliveryCardProps,
): boolean => {
  return (
    prevProps.isSelected === nextProps.isSelected &&
    prevProps.isHovered === nextProps.isHovered &&
    prevProps.delivery.updatedAt === nextProps.delivery.updatedAt &&
    prevProps.delivery.order.updatedAt === nextProps.delivery.order.updatedAt &&
    prevProps.delivery.status === nextProps.delivery.status &&
    prevProps.delivery.riderId === nextProps.delivery.riderId &&
    prevProps.delivery.etaStatus === nextProps.delivery.etaStatus &&
    prevProps.delivery.pickup.etaStart === nextProps.delivery.pickup.etaStart &&
    prevProps.delivery.pickup.etaEnd === nextProps.delivery.pickup.etaEnd &&
    prevProps.delivery.dropOff.etaStart ===
      nextProps.delivery.dropOff.etaStart &&
    prevProps.delivery.dropOff.etaEnd === nextProps.delivery.dropOff.etaEnd &&
    prevProps.delivery.externalJobId === nextProps.delivery.externalJobId &&
    prevProps.delivery.gigJob?.gigJobId === nextProps.delivery.gigJob?.gigJobId &&
    prevProps.delivery.gigJob?.riderId === nextProps.delivery.gigJob?.riderId &&
    Boolean(prevProps.delivery.gigJob?.gigJobBoard) ===
      Boolean(nextProps.delivery.gigJob?.gigJobBoard) &&
    prevProps.delivery.externalJob?.providerName ===
      nextProps.delivery.externalJob?.providerName &&
    prevProps.delivery.order.bookingApprovalStatus ===
      nextProps.delivery.order.bookingApprovalStatus
  );
};

export const GridDeliveryCard = React.memo<GridDeliveryCardProps>(
  ({
    delivery,
    isAdmin,
    isSelected,
    onHoverLeaveDelivery,
    onHoverDelivery,
    onSelectDelivery,
    onPressGoTo,
  }) => {
    const {
      deliveryId,
      cancelledAt,
      companyName,
      createdAt,
      deliveredAt,
      deliveryOptionId,
      deliveryItems,
      deliveryVehicleId,
      deliveryWindowEnd,
      deliveryWindowStart,
      descriptionColorScheme,
      earliestArrival,
      hasNotes,
      isToday,
      isHeavyside,
      orderType,
      latestArrival,
      merchantOrderReference,
      nextStopType,
      deliveryStatus,
      progressColorScheme,
      rider,
      showEta,
      showItemSizing,
      showLate,
      showMerchantName,
      notesPreview,
      showNotesPreview,
      showProgress,
      showRiderVehicle,
      showStatusDescription,
      statusDescription,
      hasShiftRider,
      updatedAt,
      isExternalJobCancelled,
      handleCardClick,
      onCloseMenu,
      isEtaPastWindow,
      showLateEtaTag,
    } = useDeliveryCardData(delivery, isAdmin, onSelectDelivery);
    return (
      <Box
        cursor="pointer"
        onClick={handleCardClick}
        onMouseEnter={() => onHoverDelivery?.(deliveryId)}
        onMouseLeave={() => onHoverLeaveDelivery?.()}
        position="relative"
      >
        <Box position="relative">
          <GridCardContainer>
            {isSelected && isAdmin ? (
              <CardAdminMenu
                deliveryStatus={deliveryStatus}
                assignedRiderId={rider?.riderId}
                onPressGoTo={onPressGoTo}
                onCloseMenu={onCloseMenu}
                deliveryDate={new Date(deliveryWindowEnd)}
                delivery={delivery}
              />
            ) : null}
            <GridCardTab
              createdAt={createdAt}
              deliveryStatus={deliveryStatus}
              vehicleId={deliveryVehicleId}
            />
            <GridCardContent>
              <CardDeliveryOption deliveryOptionId={deliveryOptionId} />
              <CardPostcodes
                collectionPostcodes={[delivery.pickup.postCode]}
                dropOffPostcodes={[delivery.dropOff.postCode]}
                orderType={orderType}
              />
              {companyName && !isAdmin ? (
                <Text fontSize={'0.9em'} color={'blackAlpha.700'}>
                  For {companyName}
                </Text>
              ) : null}
              <Divider my={2} />
              {showItemSizing && deliveryItems?.length ? (
                <>
                  <CardItemsInfo deliveryItems={deliveryItems} gap={4} />
                  <Divider my={2} />
                </>
              ) : null}
              <VStack spacing={2} alignItems={'start'}>
                <CardDeliveryWindow
                  deliveryWindowStart={deliveryWindowStart}
                  deliveryWindowEnd={deliveryWindowEnd}
                  hasEtaPastWindow={isEtaPastWindow}
                  showLateEtaTag={showLateEtaTag}
                />
                {showProgress ? (
                  <CardProgressBar
                    colorScheme={progressColorScheme}
                    deliveryWindowStart={deliveryWindowStart}
                    deliveryWindowEnd={deliveryWindowEnd}
                  />
                ) : null}
                {showEta && earliestArrival && latestArrival && nextStopType ? (
                  <CardEtaTag
                    stopType={nextStopType}
                    colorScheme={progressColorScheme}
                    earliestArrival={earliestArrival}
                    latestArrival={latestArrival}
                  />
                ) : (
                  <CardTimeRemaining
                    deliveryWindowEnd={deliveryWindowEnd}
                    showLate={showLate}
                    deliveryStatus={deliveryStatus}
                    updatedAt={updatedAt}
                    deliveredAt={deliveredAt}
                    isToday={isToday}
                    cancelledAt={cancelledAt}
                  />
                )}
              </VStack>
              <Divider my={3} />
              <HStack>
                <CardDriverInfo
                  drivers={
                    rider
                      ? [
                          {
                            firstName: rider.firstName || '',
                            lastName: rider.lastName || '',
                            vehicleId: rider.deliveryVehicle,
                          },
                        ]
                      : null
                  }
                  showVehicle={showRiderVehicle}
                  isGigJob={Boolean(delivery.gigJob?.riderId)}
                  isGigJobBoard={Boolean(
                    delivery.gigJob?.gigJobBoard && !delivery.gigJob?.riderId,
                  )}
                  showWorkType={isAdmin}
                  hasShiftRider={hasShiftRider}
                  externalProviders={
                    delivery?.externalJob?.providerName
                      ? [delivery?.externalJob?.providerName]
                      : []
                  }
                  externalJobCancelledStatuses={[isExternalJobCancelled]}
                  isAdmin={isAdmin}
                />
              </HStack>
              {showStatusDescription ? (
                <CardStatusDescription
                  colorScheme={descriptionColorScheme}
                  statusDescription={statusDescription}
                />
              ) : null}
              <Divider my={3} />
              <GridCardOrderReference
                orderReference={merchantOrderReference || ''}
                merchantName={delivery.order.Merchant?.merchantName || ''}
                invoicingName={delivery.order.invoicingName || ''}
                orderType={orderType}
                hasNotes={hasNotes}
                showMerchantName={showMerchantName}
                isHeavyside={isHeavyside}
                notesPreview={notesPreview}
                showNotesPreview={showNotesPreview}
              />
              {delivery.order.bookingApprovalStatus ? (
                <CardBookingApprovalTag
                  bookingApprovalStatus={
                    delivery.order.bookingApprovalStatus
                  }
                />
              ) : null}
            </GridCardContent>
          </GridCardContainer>
        </Box>
      </Box>
    );
  },
  shouldRerender,
);

GridDeliveryCard.displayName = 'GridDeliveryCard';
