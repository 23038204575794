import { useQuery } from '@tanstack/react-query';
import { getBookingApproval } from '@tradeaze-packages/api-client';
import { CustomUseQueryOptions } from '../shared';

export const getBookingApprovalQueryKey = (approvalId?: string) => {
  return ['bookingApproval', approvalId];
};

export const useGetBookingApproval = (
  approvalId?: string,
  options?: CustomUseQueryOptions<typeof getBookingApproval>,
) => {
  return useQuery({
    queryKey: getBookingApprovalQueryKey(approvalId),
    queryFn: () => getBookingApproval(approvalId || ''),
    enabled: !!approvalId,
    ...options,
  });
};
