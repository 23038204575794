import {
  ApproveBookingApprovalBody,
  RejectBookingApprovalBody,
} from '@tradeaze-packages/schemas';
import * as client from './client';
import { handleResponse } from './handle-response';

export const getBookingApproval = async (approvalId: string) =>
  handleResponse(
    client.getBookingApproval({
      path: { approvalId },
    }),
  );

export const approveBookingApproval = async ({
  approvalId,
  body,
}: {
  approvalId: string;
  body: ApproveBookingApprovalBody;
}) =>
  handleResponse(client.approveBookingApproval({ path: { approvalId }, body }));

export const rejectBookingApproval = async ({
  approvalId,
  body,
}: {
  approvalId: string;
  body: RejectBookingApprovalBody;
}) =>
  handleResponse(client.rejectBookingApproval({ path: { approvalId }, body }));

export const approveBookingApprovalAdmin = async ({
  approvalId,
  body,
}: {
  approvalId: string;
  body: ApproveBookingApprovalBody;
}) =>
  handleResponse(
    client.approveBookingApprovalAdmin({ path: { approvalId }, body }),
  );

export const rejectBookingApprovalAdmin = async ({
  approvalId,
  body,
}: {
  approvalId: string;
  body: RejectBookingApprovalBody;
}) =>
  handleResponse(
    client.rejectBookingApprovalAdmin({ path: { approvalId }, body }),
  );
