import {
  CLOSING_HOUR,
  CreateOrder,
  DeliveryOptionId,
  DeliveryVehicleId,
  getDeliveryOptionDescription,
  GetDeliveryOptionsResponse,
  OPENING_HOUR,
} from '@tradeaze-packages/schemas';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react';
import { WarningTwoIcon } from '@chakra-ui/icons';
import { OverThresholdAlert } from './OverThresholdAlert';

type DeliveryOptionProps = {
  deliveryVehicleId: string;
  isAnyStopOverThreshold: boolean;
  deliveryOptionsQueryLoading: boolean;
  deliveryOptionsQueryError: {
    error: { message: string; data?: unknown };
  } | null;
  deliveryOptionsByVehicle?: GetDeliveryOptionsResponse;
  deliveryOptionId: DeliveryOptionId;
  isInvalidScheduledTime: boolean;
  scheduledTime: string;
  handleChangeScheduledTime: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isVehicleAvailable: boolean;
};

const renderDeliveryOptions = (
  deliveryVehicleId: DeliveryVehicleId,
  deliveryOptionsByVehicle: GetDeliveryOptionsResponse,
  value: DeliveryOptionId | null,
  error: {
    error: { message: string; data?: unknown };
  } | null,
) => {
  if (error) {
    return <Text color="red.500">Error loading delivery options</Text>;
  }

  const options = deliveryOptionsByVehicle[deliveryVehicleId]?.options;

  if (!options?.length) {
    return <Text>No delivery options found</Text>;
  }

  return options.map((option) => (
    <Radio
      key={option.deliveryOptionId}
      value={option.deliveryOptionId}
      isChecked={value === option.deliveryOptionId}
      isDisabled={!option.isAvailable}
      data-cy={`delivery-option-${option.deliveryOptionId}`}
    >
      <Text>{option.name}</Text>
    </Radio>
  ));
};

export const DeliveryOption = ({
  deliveryVehicleId,
  isAnyStopOverThreshold,
  deliveryOptionsQueryLoading,
  deliveryOptionsQueryError,
  deliveryOptionsByVehicle,
  deliveryOptionId,
  isInvalidScheduledTime,
  scheduledTime,
  handleChangeScheduledTime,
  isVehicleAvailable,
}: DeliveryOptionProps) => {
  const { control } = useFormContext<CreateOrder>();

  const showStopOverThresholdAlert = isAnyStopOverThreshold;

  const showNoOptionsAvailableAlert =
    !isVehicleAvailable && !deliveryOptionsQueryLoading;

  return (
    <Box>
      {deliveryVehicleId ? (
        <FormControl>
          <FormLabel>Delivery Option</FormLabel>
          {showStopOverThresholdAlert && (
            <OverThresholdAlert deliveryVehicleId={deliveryVehicleId} />
          )}
          <Controller
            name="deliveryOption"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <RadioGroup
                  onChange={(value: DeliveryOptionId) => {
                    onChange(value);
                  }}
                  value={value ?? null}
                  data-cy={'delivery-option'}
                >
                  <Stack
                    direction={[
                      'column',
                      'column',
                      'column',
                      'column',
                      'row',
                      'row',
                    ]}
                    gap={3}
                    p={3}
                  >
                    {renderDeliveryOptions(
                      deliveryVehicleId as DeliveryVehicleId,
                      deliveryOptionsByVehicle ?? {},
                      value,
                      deliveryOptionsQueryError,
                    )}
                  </Stack>
                  <Text color={'blackAlpha.500'} fontSize={14}>
                    {getDeliveryOptionDescription(deliveryOptionId)}
                  </Text>
                  {deliveryOptionId === 'BIKE_SCHEDULED' && (
                    <FormControl my={4} isInvalid={isInvalidScheduledTime}>
                      <Input
                        type={'time'}
                        value={scheduledTime}
                        onChange={handleChangeScheduledTime}
                        min={`${OPENING_HOUR}:00`}
                        max={`${CLOSING_HOUR}:00`}
                      />
                      <FormErrorMessage>
                        Scheduled time should be within operating hours and
                        suitably far ahead of the current time
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </RadioGroup>
              );
            }}
          />
        </FormControl>
      ) : null}
      {showNoOptionsAvailableAlert ? (
        <Text color="orange.400" fontSize={14}>
          <Icon as={WarningTwoIcon} mr={1} />
          No options left for this date/vehicle. Contact Tradeaze for any
          enquiries.
        </Text>
      ) : null}
    </Box>
  );
};
