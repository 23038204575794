import {
  OpenAPIRegistry,
  extendZodWithOpenApi,
} from '@asteasolutions/zod-to-openapi';
import {
  CreateMerchantBodySchema,
  CreateMerchantCompanyAddressBodySchema,
  CreateMerchantCompanyAddressResponseSchema,
  CreateMerchantCompanyBodySchema,
  CreateMerchantCompanyResponseSchema,
  CreateMerchantResponseSchema,
  CreateSiteContactBodySchema,
  CreateSiteContactResponseSchema,
  DeleteAddressResponseSchema,
  DeleteMerchantCompanyResponseSchema,
  DeleteMerchantResponseSchema,
  DeleteSiteContactResponseSchema,
  GetAddressesByCompanyQuerySchema,
  GetAddressesByCompanyResponseSchema,
  GetAddressesByMerchantQuerySchema,
  GetAddressesByMerchantResponseSchema,
  GetAllMerchantsResponseSchema,
  GetCompaniesByMerchantQuerySchema,
  GetCompaniesByMerchantResponseSchema,
  GetCompanyByIdResponseSchema,
  GetMerchantByIdResponseSchema,
  GetMerchantProfileResponseSchema,
  GetMerchantProfilesQuerySchema,
  GetMerchantProfilesResponseSchema,
  GetSiteContactsByCompanyQuerySchema,
  GetSiteContactsByCompanyResponseSchema,
  UpdateAddressBodySchema,
  UpdateAddressResponseSchema,
  UpdateMerchantBodySchema,
  UpdateMerchantBookingApproversBodySchema,
  UpdateMerchantBookingApproversResponseSchema,
  UpdateMerchantBookingApproversPathParamsSchema,
  UpdateMerchantCategoryBodySchema,
  UpdateMerchantCategoryResponseSchema,
  UpdateMerchantResponseSchema,
  UpdateSiteContactBodySchema,
  UpdateSiteContactResponseSchema,
} from '../../merchant-service'
import { z } from 'zod';
import { commonErrorResponses } from './common';

extendZodWithOpenApi(z);

const registry = new OpenAPIRegistry();

const basePath = '/merchant';

/**
 * POST /createMerchant
 */
registry.registerPath({
  operationId: 'createMerchant',
  path: `${basePath}/createMerchant`,
  description: 'Create a new merchant.',
  method: 'post',
  request: {
    body: {
      content: {
        'application/json': {
          schema: CreateMerchantBodySchema,
        },
      },
    },
  },
  responses: {
    201: {
      description: 'Merchant created successfully.',
      content: {
        'application/json': {
          schema: CreateMerchantResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /createMerchantCompany
 */
registry.registerPath({
  operationId: 'createMerchantCompany',
  path: `${basePath}/createMerchantCompany`,
  description: 'Create a new merchant company.',
  method: 'post',
  request: {
    body: {
      content: {
        'application/json': {
          schema: CreateMerchantCompanyBodySchema,
        },
      },
    },
  },
  responses: {
    201: {
      description: 'Merchant company created successfully.',
      content: {
        'application/json': {
          schema: CreateMerchantCompanyResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /createMerchantCompanyAddress
 */
registry.registerPath({
  operationId: 'createMerchantCompanyAddress',
  path: `${basePath}/createMerchantCompanyAddress`,
  description: 'Create a new merchant company address.',
  method: 'post',
  request: {
    body: {
      content: {
        'application/json': {
          schema: CreateMerchantCompanyAddressBodySchema,
        },
      },
    },
  },
  responses: {
    201: {
      description: 'Merchant company address created successfully.',
      content: {
        'application/json': {
          schema: CreateMerchantCompanyAddressResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /createSiteContact
 */
registry.registerPath({
  operationId: 'createSiteContact',
  path: `${basePath}/createSiteContact`,
  description: 'Create a new site contact.',
  method: 'post',
  request: {
    body: {
      content: {
        'application/json': {
          schema: CreateSiteContactBodySchema,
        },
      },
    },
  },
  responses: {
    201: {
      description: 'Site contact created successfully.',
      content: {
        'application/json': {
          schema: CreateSiteContactResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * DELETE /deleteMerchantById/{merchantId}
 */
registry.registerPath({
  operationId: 'deleteMerchantById',
  path: `${basePath}/deleteMerchantById/{merchantId}`,
  description: 'Delete a merchant by ID.',
  method: 'delete',
  request: {
    params: z.object({
      merchantId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Merchant deleted successfully.',
      content: {
        'application/json': {
          schema: DeleteMerchantResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * DELETE /deleteMerchantCompany/{merchantId}/{companyId}
 */
registry.registerPath({
  operationId: 'deleteMerchantCompany',
  path: `${basePath}/deleteMerchantCompany/{merchantId}/{companyId}`,
  description: 'Delete a merchant company by ID.',
  method: 'delete',
  request: {
    params: z.object({
      companyId: z.string(),
      merchantId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Merchant company deleted successfully.',
      content: {
        'application/json': {
          schema: DeleteMerchantCompanyResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * DELETE /deleteMerchantCompanyAddress/{addressId}
 */
registry.registerPath({
  operationId: 'deleteMerchantCompanyAddress',
  path: `${basePath}/deleteMerchantCompanyAddress/{addressId}`,
  description: 'Delete a merchant company address by ID.',
  method: 'delete',
  request: {
    params: z.object({
      addressId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Merchant company address deleted successfully.',
      content: {
        'application/json': {
          schema: DeleteAddressResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * DELETE /deleteSiteContact/{siteContactId}
 */
registry.registerPath({
  operationId: 'deleteSiteContact',
  path: `${basePath}/deleteSiteContact/{siteContactId}`,
  description: 'Delete a site contact by ID.',
  method: 'delete',
  request: {
    params: z.object({
      siteContactId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Site contact deleted successfully.',
      content: {
        'application/json': {
          schema: DeleteSiteContactResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getAddressesByCompanyId/{companyId}
 */
registry.registerPath({
  operationId: 'getAddressesByCompanyId',
  path: `${basePath}/getAddressesByCompanyId/{companyId}`,
  description: 'Get addresses by company ID.',
  method: 'get',
  request: {
    params: z.object({
      companyId: z.string(),
    }),
    query: GetAddressesByCompanyQuerySchema,
  },
  responses: {
    200: {
      description: 'Addresses retrieved successfully.',
      content: {
        'application/json': {
          schema: GetAddressesByCompanyResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getAddressesByMerchantId/{merchantId}
 */
registry.registerPath({
  operationId: 'getAddressesByMerchantId',
  path: `${basePath}/getAddressesByMerchantId/{merchantId}`,
  description: 'Get addresses by merchant ID.',
  method: 'get',
  request: {
    params: z.object({
      merchantId: z.string(),
    }),
    query: GetAddressesByMerchantQuerySchema,
  },
  responses: {
    200: {
      description: 'Addresses retrieved successfully.',
      content: {
        'application/json': {
          schema: GetAddressesByMerchantResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * DELETE /deleteMerchantCompanyAddress/{addressId}
 */
registry.registerPath({
  operationId: 'deleteMerchantCompanyAddress',
  path: `${basePath}/deleteMerchantCompanyAddress/{addressId}`,
  description: 'Delete a merchant company address by ID.',
  method: 'delete',
  request: {
    params: z.object({
      addressId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Merchant company address deleted successfully.',
      content: {
        'application/json': {
          schema: DeleteAddressResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getAllMerchants
 */
registry.registerPath({
  operationId: 'getAllMerchants',
  path: `${basePath}/getAllMerchants`,
  description: 'Get all merchants.',
  method: 'get',
  responses: {
    200: {
      description: 'Merchants retrieved successfully.',
      content: {
        'application/json': {
          schema: GetAllMerchantsResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getCompaniesByMerchantId/{merchantId}
 */
registry.registerPath({
  operationId: 'getCompaniesByMerchantId',
  path: `${basePath}/getCompaniesByMerchantId/{merchantId}`,
  description: 'Get companies by merchant ID.',
  method: 'get',
  request: {
    params: z.object({
      merchantId: z.string(),
    }),
    query: GetCompaniesByMerchantQuerySchema,
  },
  responses: {
    200: {
      description: 'Companies retrieved successfully.',
      content: {
        'application/json': {
          schema: GetCompaniesByMerchantResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getCompanyById/{companyId}
 */
registry.registerPath({
  operationId: 'getCompanyById',
  path: `${basePath}/getCompanyById/{companyId}`,
  description: 'Get company by ID.',
  method: 'get',
  request: {
    params: z.object({
      companyId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Company retrieved successfully.',
      content: {
        'application/json': {
          schema: GetCompanyByIdResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getMerchantById/{merchantId}
 */
registry.registerPath({
  operationId: 'getMerchantById',
  path: `${basePath}/getMerchantById/{merchantId}`,
  description: 'Get merchant by ID.',
  method: 'get',
  request: {
    params: z.object({
      merchantId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Merchant retrieved successfully.',
      content: {
        'application/json': {
          schema: GetMerchantByIdResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getMerchantProfile/{merchantId}
 */
registry.registerPath({
  operationId: 'getMerchantProfile',
  path: `${basePath}/getMerchantProfile/{merchantId}`,
  description: 'Get merchant profile by ID.',
  method: 'get',
  request: {
    params: z.object({
      merchantId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Merchant profile retrieved successfully.',
      content: {
        'application/json': {
          schema: GetMerchantProfileResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getMerchantProfiles/{merchantId}
 */
registry.registerPath({
  operationId: 'getMerchantProfiles',
  path: `${basePath}/getMerchantProfiles/{merchantId}`,
  description: 'Get merchant profiles by ID.',
  method: 'get',
  request: {
    query: GetMerchantProfilesQuerySchema,
  },
  responses: {
    200: {
      description: 'Merchant profiles retrieved successfully.',
      content: {
        'application/json': {
          schema: GetMerchantProfilesResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getSiteContactsByCompany/{companyId}
 */
registry.registerPath({
  operationId: 'getSiteContactsByCompany',
  path: `${basePath}/getSiteContactsByCompany/{companyId}`,
  description: 'Get site contacts by company ID.',
  method: 'get',
  request: {
    params: z.object({
      companyId: z.string(),
    }),
    query: GetSiteContactsByCompanyQuerySchema,
  },
  responses: {
    200: {
      description: 'Site contacts retrieved successfully.',
      content: {
        'application/json': {
          schema: GetSiteContactsByCompanyResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * PUT /updateAddress/{addressId}
 */
registry.registerPath({
  operationId: 'updateAddress',
  path: `${basePath}/updateAddress/{addressId}`,
  description: 'Update an address by ID.',
  method: 'put',
  request: {
    params: z.object({
      addressId: z.string(),
    }),
    body: {
      content: {
        'application/json': {
          schema: UpdateAddressBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Address updated successfully.',
      content: {
        'application/json': {
          schema: UpdateAddressResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /updateMerchantById/{merchantId}
 */
registry.registerPath({
  operationId: 'updateMerchantById',
  path: `${basePath}/updateMerchantById/{merchantId}`,
  description: 'Update a merchant by ID.',
  method: 'post',
  request: {
    params: z.object({
      merchantId: z.string(),
    }),
    body: {
      content: {
        'application/json': {
          schema: UpdateMerchantBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Merchant updated successfully.',
      content: {
        'application/json': {
          schema: UpdateMerchantResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /updateMerchantCategory/{merchantId}
 */
registry.registerPath({
  operationId: 'updateMerchantCategory',
  path: `${basePath}/updateMerchantCategory/{merchantId}`,
  description: 'Update a merchant category by ID.',
  method: 'post',
  request: {
    params: z.object({
      merchantId: z.string(),
    }),
    body: {
      content: {
        'application/json': {
          schema: UpdateMerchantCategoryBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Merchant category updated successfully.',
      content: {
        'application/json': {
          schema: UpdateMerchantCategoryResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /updateSiteContact
 */
registry.registerPath({
  operationId: 'updateSiteContact',
  path: `${basePath}/updateSiteContact`,
  description: 'Update a site contact.',
  method: 'post',
  request: {
    body: {
      content: {
        'application/json': {
          schema: UpdateSiteContactBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Site contact updated successfully.',
      content: {
        'application/json': {
          schema: UpdateSiteContactResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /updateMerchantBookingApprovers/{merchantId}
 */
registry.registerPath({
  operationId: 'updateMerchantBookingApprovers',
  path: `${basePath}/{merchantId}/booking-approvers`,
  description: 'Update a merchant booking approvers by ID.',
  method: 'put',
  request: {
    params: UpdateMerchantBookingApproversPathParamsSchema,
    body: {
      content: {
        'application/json': {
          schema: UpdateMerchantBookingApproversBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Merchant booking approvers updated successfully.',
      content: {
        'application/json': {
          schema: UpdateMerchantBookingApproversResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

export const merchantApiRegistry = registry;
