import {
  Accordion,
  Box,
  Button,
  Divider,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { HydratedDeliveryStop, Order } from '@tradeaze-packages/schemas';
import { useCancelDelivery } from '@tradeaze/frontend/hooks';
import { shouldChargeCancellationFee } from '@tradeaze/shared/utils';
import React from 'react';
import toast from 'react-hot-toast';
import { GrLocation } from 'react-icons/gr';
import {
  DeliveryStatusTag,
  DeliveryStopAccordionItem,
  DeliveryStopSummary,
} from '../../delivery';
import { RiderAvatar } from '../../rider';
import { OrderSection } from '../order-section';
import { CancelConfirmation } from './CancelConfirmation';
import { shouldShowCancelDelivery } from './common';

const MultiStopFooter = ({
  deliveryStop,
  isAdmin,
  order,
}: {
  deliveryStop: HydratedDeliveryStop;
  isAdmin: boolean;
  order: Order;
}) => {
  const cancelDeliveryMutation = useCancelDelivery({
    onError: () => {
      toast.error('Error updating delivery status');
    },
  });

  return (
    <HStack justifyContent={'space-between'}>
      <HStack spacing={3}>
        <DeliveryStatusTag status={deliveryStop.delivery?.status} />
        {deliveryStop.delivery?.rider ? (
          <RiderAvatar
            riderId={deliveryStop.delivery.rider.riderId}
            firstName={deliveryStop.delivery.rider.firstName}
            lastName={deliveryStop.delivery.rider.lastName}
            size={'xs'}
          />
        ) : null}
      </HStack>
      <Box>
        {isAdmin &&
        shouldShowCancelDelivery(deliveryStop) &&
        deliveryStop.type === 'PICK_UP' ? (
          <CancelConfirmation
            cancelType="pickup"
            shouldChargeFee={shouldChargeCancellationFee(
              new Date(order.createdAt),
              new Date(order.deliveryWindowEnd),
            )}
            isLoading={cancelDeliveryMutation.isLoading}
            onConfirm={(cancellationReason) =>
              cancelDeliveryMutation.mutate({
                deliveryId: deliveryStop.delivery?.deliveryId || '',
                body: {
                  cancellationReason,
                },
              })
            }
            renderButton={({ onClick, isLoading }) => (
              <Button
                onClick={onClick}
                isLoading={isLoading}
                colorScheme="red"
                variant="outline"
                size="sm"
              >
                Cancel Pickup
              </Button>
            )}
          />
        ) : null}
      </Box>
    </HStack>
  );
};

const getTestId = (deliveryStop: HydratedDeliveryStop, index: number) => {
  return deliveryStop.type === 'DROP_OFF' ? `drop-off-${index}` : `pick-up-${index}`;
};

export const DeliveryStopsSummary: React.FunctionComponent<{
  deliveryStops: HydratedDeliveryStop[];
  order: Order;
  proofOfDeliveryBucketname: string | null;
  isAdmin?: boolean;
}> = ({ deliveryStops, proofOfDeliveryBucketname, isAdmin = false, order }) => {
  return deliveryStops.length > 1 ? (
    <Accordion allowToggle defaultIndex={[0]}>
      <VStack spacing={10} width={'100%'}>
        {deliveryStops.map((deliveryStop, index) => (
          <OrderSection width={'100%'} key={index}>
            <DeliveryStopAccordionItem
              deliveryStop={deliveryStop}
              type={order.type}
              title={`${
                deliveryStop.type === 'DROP_OFF' ? 'Drop off' : 'Pick Up'
              } ${index + 1}`}
              titleIcon={<GrLocation />}
              proofOfDeliveryBucketname={proofOfDeliveryBucketname}
              isAdmin={isAdmin}
              testId={getTestId(deliveryStop, index)}
            />
            <Divider mt={3} mb={5} />
            <MultiStopFooter
              deliveryStop={deliveryStop}
              isAdmin={isAdmin}
              order={order}
            />
          </OrderSection>
        ))}
      </VStack>
    </Accordion>
  ) : (
    deliveryStops.map((deliveryStop, index) => (
      <OrderSection
        key={index}
        name={`${deliveryStop.type === 'DROP_OFF' ? 'Drop off' : 'Pick Up'}`}
        icon={<GrLocation />}
      >
        <DeliveryStopSummary
          deliveryStop={deliveryStop}
          proofOfDeliveryBucketname={proofOfDeliveryBucketname}
          isAdmin={isAdmin}
          orderType={order.type}
          testId={getTestId(deliveryStop, index)}
        />
      </OrderSection>
    ))
  );
};
