import { z } from 'zod';

export const UpdateMerchantBookingApproversPathParamsSchema = z.object({
  merchantId: z.string(),
});

export const UpdateMerchantBookingApproversBodySchema = z.object({
  bookingUserIds: z.array(z.string()),
});

export const UpdateMerchantBookingApproversResponseSchema = z.object({
  bookingUserIds: z.array(z.string()),
});

export type UpdateMerchantBookingApproversPathParams = z.infer<
  typeof UpdateMerchantBookingApproversPathParamsSchema
>;

export type UpdateMerchantBookingApproversBody = z.infer<
  typeof UpdateMerchantBookingApproversBodySchema
>;

export type UpdateMerchantBookingApproversResponse = z.infer<
  typeof UpdateMerchantBookingApproversResponseSchema
>;
