import { Box, Center, Icon, Text } from '@chakra-ui/react';
import { TradeazeLogoNoText } from '../brand/TradeazeLogoNoText';

export const PageError: React.FunctionComponent<{
  text?: string;
  color?: React.ComponentProps<typeof Box>['color'];
}> = ({ text = 'Something went wrong', color = 'orange.300' }) => {
  return (
    <Center h="60vh" flexDirection={'column'} color={color}>
      <Box>
        <Icon
          as={TradeazeLogoNoText}
          fill={color}
          fontSize={200}
          h="120"
        />
      </Box>
      <Box>
        <Text>{text}</Text>
      </Box>
    </Center>
  );
};
