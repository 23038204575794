import { useMutation } from '@tanstack/react-query';
import { rejectBookingApproval } from '@tradeaze-packages/api-client';
import { CustomUseMutationOptions } from '../shared';
import { useInvalidateQueries } from '../utility';
import { getBookingApprovalQueryKey } from './useGetBookingApproval';

export const useRejectBooking = (
  options?: CustomUseMutationOptions<typeof rejectBookingApproval>,
) => {
  const invalidateQueries = useInvalidateQueries();

  return useMutation({
    mutationFn: rejectBookingApproval,
    onSuccess: (_data, variables) => {
      invalidateQueries([getBookingApprovalQueryKey(variables.approvalId)]);
    },
    ...options,
  });
}; 