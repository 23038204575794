import {
  CreateOrder,
  CreateOrderMultiCollection,
  MerchantAccount,
  ExistingOrder,
  buildCreateOrderSchema,
} from '@tradeaze-packages/schemas';
import { PostHog } from 'posthog-js';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useOrderSubForms } from './useOrderSubForms';
import {
  isBookingInformationSectionComplete,
  isDropOffSectionComplete,
  isInvoicingSectionComplete,
  isItemDetailsSectionComplete,
  isPickupSectionComplete,
  isPricingConfigSame,
  isVehicleDetailsSectionComplete,
} from '../utils';
import { useEffect, useState } from 'react';
import { useDeliveryPrice } from './useDeliveryPrice';
import { useMultiCollectionFormSubmission } from './useMultiCollectionFormSubmission';
import { AddMarkerFn, RemoveMarkerFn } from '../../map';
import { createOrderDefaultValues } from './utils/createOrderDefaultValues';

type UseMultiCollectionForm = {
  merchantAccount?: MerchantAccount;
  isAdmin?: boolean;
  posthog?: PostHog;
  onSubmit: (order: CreateOrder) => void;
  setShowMainBackButton?: (value: boolean) => void;
  existingOrder?: ExistingOrder;
  addPickupMarker: AddMarkerFn;
  removePickupMarker: RemoveMarkerFn;
};

export const useMultiCollectionForm = ({
  merchantAccount,
  isAdmin,
  posthog,
  onSubmit,
  setShowMainBackButton,
  existingOrder,
  addPickupMarker,
  removePickupMarker,
}: UseMultiCollectionForm) => {
  const form = useForm<CreateOrder>({
    defaultValues: createOrderDefaultValues({
      orderType: 'MULTI_COLLECTION',
      merchantAccount,
      existingOrder,
    }),
    resolver: zodResolver(
      buildCreateOrderSchema('MULTI_COLLECTION', merchantAccount),
    ),
    mode: 'all',
  });

  const multiCollectionDetails = form.watch() as CreateOrderMultiCollection;

  const [showPickupSection, setShowPickupSection] = useState(false);
  const [selectedPickupIndex, setSelectedPickupIndex] = useState<number | null>(
    null,
  );
  const [existingPickups, setExistingPickups] = useState(0);
  const anyStopHasHeavySideItems =
    multiCollectionDetails.pickups?.some(
      (pickup) => pickup?.deliveryItems[0]?.heavySideItems,
    ) ?? false;

  const {
    pickupForm,
    dropOffForm,
    itemForm,
    pickupDetails,
    dropoffDetails,
    itemDetails,
    resetPickupForm,
    resetItemForm,
  } = useOrderSubForms({
    merchantAccount,
    existingStopData: existingOrder
      ? {
          pickupDetails: existingOrder.pickupDetails,
          dropOffDetails: existingOrder.dropoffDetails,
        }
      : undefined,
    orderType: 'MULTI_COLLECTION',
  });

  const isPickupComplete = isPickupSectionComplete(pickupDetails, pickupForm);
  const isItemDetailsComplete = isItemDetailsSectionComplete(
    itemDetails,
    itemForm,
  );
  const isDropOffComplete = isDropOffSectionComplete(
    dropoffDetails,
    dropOffForm,
  );
  const isVehicleDetailsComplete = isVehicleDetailsSectionComplete(
    multiCollectionDetails,
    form,
  );

  const isBookingInformationComplete = isBookingInformationSectionComplete(
    form,
    merchantAccount?.referencePrefix,
  );

  const isBookingApprovalRequired =
    merchantAccount?.isBookingApprovalRequired || false;

  const isInvoicingComplete = isInvoicingSectionComplete(form);

  const resetForms = () => {
    resetPickupForm();
    resetItemForm();
  };

  const { handleAddPickup, handleUpdatePickup, handleSubmit } =
    useMultiCollectionFormSubmission({
      pickupForm,
      dropOffForm,
      itemForm,
      form,
      resetForms,
      selectedPickupIndex,
      setSelectedPickupIndex,
      setShowPickupSection,
      multiCollectionDetails,
      onSubmit,
      setShowMainBackButton,
    });

  const currentOrder: Partial<CreateOrderMultiCollection> = {
    ...multiCollectionDetails,
    pickups: multiCollectionDetails.pickups,
    dropOff: { ...dropoffDetails, type: 'DROP_OFF' },
  };

  const handleAddPickupButton = () => {
    setExistingPickups(multiCollectionDetails?.pickups?.length ?? 0);
    resetForms();
    setShowMainBackButton?.(false);
    setShowPickupSection(true);
  };

  const handleOrderCardSelect = (index: number) => {
    const pickup = form.getValues('pickups')[index];
    if (pickup) {
      pickupForm.reset(pickup);
      itemForm.reset(pickup.deliveryItems[0]);
      setSelectedPickupIndex(index);
      setShowMainBackButton?.(false);
      setShowPickupSection(true);
    }
  };

  const handleCreateBackButton = () => {
    setShowMainBackButton?.(true);
    setShowPickupSection(false);
  };

  const handleEditBackButton = () => {
    const existingPosition =
      selectedPickupIndex !== null
        ? multiCollectionDetails.pickups[selectedPickupIndex].position
        : null;
    if (existingPosition && selectedPickupIndex !== null) {
      addPickupMarker({
        id: (selectedPickupIndex + 1).toString(),
        position: existingPosition,
        stopSequence: selectedPickupIndex + 1,
      });
    }
    resetForms();
    setSelectedPickupIndex(null);
    setShowMainBackButton?.(true);
    setShowPickupSection(false);
  };

  const allStops: { postCode: string; type: 'PICK_UP' | 'DROP_OFF' }[] = [
    ...(multiCollectionDetails.pickups?.map((pickup) => ({
      postCode: pickup.postCode,
      type: 'PICK_UP' as const,
    })) || []),
    {
      postCode: dropoffDetails.postCode,
      type: 'DROP_OFF' as const,
    },
  ].filter((stop) => stop.postCode);

  const currentPricingConfig = {
    deliveryOption: multiCollectionDetails.deliveryOption,
    deliveryVehicle: multiCollectionDetails.deliveryVehicle,
    deliveryWindowStart: multiCollectionDetails.deliveryWindowStart,
    heavySideItems: anyStopHasHeavySideItems,
    allStops,
  };

  const isSamePricingConfig = isPricingConfigSame(
    currentPricingConfig,
    existingOrder,
  );

  const {
    isDeliveryPriceLoading,
    deliveryPriceErrorMessage,
    thresholdDistances,
  } = useDeliveryPrice({
    merchantAccount,
    createOrderDetails: multiCollectionDetails,
    allStops,
    isAdmin,
    posthog,
    form,
    pickupForm,
    dropOffForm,
    heavySideItems: anyStopHasHeavySideItems,
    isSamePricingConfig,
    existingOrder: Boolean(existingOrder),
    isMultiCollection: true,
  });

  const hidePriorityDelivery =
    merchantAccount?.featureFlags?.hidePriorityDelivery || false;

  const pickupCount = (multiCollectionDetails?.pickups?.length ?? 0) + 1;

  const restrictedEditing =
    !isAdmin &&
    existingOrder?.pickupDetails?.some(
      (pickup) => pickup.status === 'CONFIRMED',
    );

  const handleRemoveOrderCard = (index: number, stopSequence: number) => {
    const updatedPickups = multiCollectionDetails.pickups?.filter(
      (_, i) => i !== index,
    );
    form.setValue('pickups', updatedPickups);
    removePickupMarker(stopSequence.toString());
  };

  useEffect(() => {
    if (existingOrder?.pickupDetails) {
      existingOrder.pickupDetails.forEach((pickup, index) => {
        if (pickup.position) {
          addPickupMarker({
            id: (index + 1).toString(),
            position: pickup.position,
            stopSequence: index + 1,
          });
        }
      });
    }
  }, [addPickupMarker, existingOrder?.pickupDetails]);

  return {
    pickupForm,
    itemForm,
    isPickupComplete,
    isItemDetailsComplete,
    selectedPickupIndex,
    showPickupSection,
    handleAddPickup,
    multiCollectionDetails,
    handleOrderCardSelect,
    handleUpdatePickup,
    handleCreateBackButton,
    handleEditBackButton,
    handleAddPickupButton,
    existingPickups,
    pickupCount,
    restrictedEditing,
    dropOffForm,
    itemDetails,
    isDropOffComplete,
    isDeliveryPriceLoading,
    deliveryPriceErrorMessage,
    thresholdDistances,
    hidePriorityDelivery,
    isVehicleDetailsComplete,
    form,
    handleSubmit,
    isBookingInformationComplete,
    handleRemoveOrderCard,
    isInvoicingComplete,
    currentOrder,
    isBookingApprovalRequired,
  };
};
