import { CheckCircleIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { Box, Tag, TagLabel } from '@chakra-ui/react';
import { BookingApprovalStatus } from '@tradeaze-packages/schemas';
import { FaHourglassHalf } from 'react-icons/fa';

interface Props {
  status: BookingApprovalStatus;
}

const getTagProps = (status: BookingApprovalStatus) => {
  switch (status) {
    case 'APPROVED':
      return {
        name: 'Booking Approved',
        icon: <CheckCircleIcon />,
        bg: 'green.200',
        textColor: 'green.900',
      };
    case 'REJECTED':
      return {
        name: 'Booking Approval Rejected',
        icon: <SmallCloseIcon fontSize={20} />,
        bg: 'red.200',
        textColor: 'red.900',
      };
    case 'PENDING':
      return {
        name: 'Booking Approval Pending',
        icon: <FaHourglassHalf fontSize={12} />,
        bg: 'gray.200',
        textColor: 'gray.900',
      };
    default:
      return null as never;
  }
};

export const BookingApprovalStatusTag = ({ status }: Props) => {
  const { bg, name, icon, textColor } = getTagProps(status);

  return (
    <Tag
      size="md"
      // colorScheme={colorScheme}
      color={textColor}
      bg={bg}
      alignItems="center"
    >
      <Box mr={1}>{icon}</Box>
      <TagLabel data-cy="booking-approval-status">{name}</TagLabel>
    </Tag>
  );
};

