import { CopyIcon } from '@chakra-ui/icons';
import {
  Box,
  BoxProps,
  Button,
  Flex,
  List,
  ListItem,
  Text,
} from '@chakra-ui/react';
import {
  BookingUser,
  HydratedBookingApproval
} from '@tradeaze-packages/schemas';
import { getMerchantUrlFromCurrentUrl } from '@tradeaze/frontend/utils';
import { buildBookingApprovalUrl } from '@tradeaze/shared/utils';
import React from 'react';
import toast from 'react-hot-toast';
import { formatBookingUserName } from './utils/formatBookingUserName';

const getTitle = (status: string) => {
  switch (status) {
    case 'APPROVED':
      return 'Booking Approved';
    case 'REJECTED':
      return 'Booking Approval Rejected';
    case 'PENDING':
      return 'Booking Approval Pending';
    default:
      return 'Unknown Approval Status';
  }
};

const getStatusStyles = (status: string) => {
  switch (status) {
    case 'APPROVED':
      return {
        bg: 'green.50',
        borderColor: 'green.200',
        textColor: 'green.600',
      };
    case 'REJECTED':
      return {
        bg: 'red.50',
        borderColor: 'red.200',
        textColor: 'red.600',
      };
    case 'PENDING':
      return {
        bg: 'yellow.50',
        borderColor: 'yellow.200',
        textColor: 'yellow.700',
      };
    default:
      return {
        bg: 'gray.50',
        borderColor: 'gray.200',
        textColor: 'gray.600',
      };
  }
};

const BookingApproversList: React.FC<{
  bookingApprovers: BookingUser[];
  showUserApprovalLink: boolean;
  approvalId: string;
}> = ({ bookingApprovers, showUserApprovalLink, approvalId }) => {
  
  const handleCopyLink = (userId: string) => {
    const url = buildBookingApprovalUrl({
      approvalId,
      userId,
      baseUrl: getMerchantUrlFromCurrentUrl(),
    });
    navigator.clipboard.writeText(url).then(() => {
      toast.success('Link copied');
    });
  };

  return (
    <List spacing={2}>
      {bookingApprovers.map((approver) => {
        const nameAndEmail = `${formatBookingUserName(approver)} (${
          approver.email
        })`;
        return (
          <ListItem
            key={approver.id}
            display="flex"
            alignItems="center"
            gap={2}
            flexWrap="wrap"
            position="relative"
            pl={6}
            _before={{
              content: '"•"',
              position: 'absolute',
              left: 2,
              color: 'gray.600',
              fontSize: 'lg',
            }}
          >
            <Text flex="1">{nameAndEmail}</Text>
            {showUserApprovalLink && (
              <Button
                size="sm"
                variant="ghost"
                leftIcon={<CopyIcon />}
                onClick={() => handleCopyLink(approver.id)}
                colorScheme="blue"
              >
                Copy Approval Link
              </Button>
            )}
          </ListItem>
        );
      })}
    </List>
  );
};

export const BookingApprovalStatusBanner: React.FC<{
  bookingApproval: HydratedBookingApproval;
  boxProps?: BoxProps;
  bookingApprovers?: BookingUser[];
  isAdmin: boolean;
}> = ({ bookingApproval, boxProps, bookingApprovers, isAdmin }) => {
  const styles = getStatusStyles(bookingApproval.status);

  const { id, status, actionedUser, comments, actionedAt } = bookingApproval;

  const actionedByName = actionedUser
    ? formatBookingUserName(actionedUser)
    : null;

  const actionedByEmail = actionedUser?.email ?? null;

  const isPending = status === 'PENDING';

  const showUserApprovalLink = isAdmin;

  const secondaryTextColor = 'gray.600';

  return (
    <Box
      w="full"
      p={6}
      bg={styles.bg}
      borderRadius="md"
      border="1px solid"
      borderColor={styles.borderColor}
      mb={6}
      {...boxProps}
    >
      <Flex direction="column" gap={3}>
        <Flex justify="space-between" align="center">
          <Box>
            <Text fontWeight="bold" mb={1} color={styles.textColor}>
              {getTitle(status)}
            </Text>
            {isAdmin && id && (
              <Text fontSize="sm" color={styles.textColor}>
                ID: {id}
              </Text>
            )}
          </Box>

          <Text fontSize="sm" color={secondaryTextColor}>
            {actionedAt
              ? new Date(actionedAt).toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                })
              : ''}
          </Text>
        </Flex>
        {actionedByName && actionedByEmail ? (
          <Text fontSize="sm" color={secondaryTextColor}>
            By {actionedByName} ({actionedByEmail})
          </Text>
        ) : null}
        {comments && (
          <Box mt={2}>
            <Text fontSize="sm" color={secondaryTextColor} mb={1}>
              Comments:
            </Text>
            <Text fontSize="sm" p={3} bg="white" borderRadius="md">
              {comments}
            </Text>
          </Box>
        )}
        {isPending && bookingApprovers && bookingApprovers.length > 0 && (
          <Box paddingLeft={3} fontSize="sm" color={secondaryTextColor}>
            <Text fontWeight="semibold" mb={2}>
              Approvers
            </Text>
            <BookingApproversList
              bookingApprovers={bookingApprovers}
              showUserApprovalLink={showUserApprovalLink}
              approvalId={id}
            />
          </Box>
        )}
      </Flex>
    </Box>
  );
};
