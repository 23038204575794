import {
    Box,
    Button,
    Checkbox,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text
} from '@chakra-ui/react';
import React, { useState } from 'react';

export const ApprovalConfirmationModal: React.FC<{
    isOpen: boolean;
    onClose: () => void;
    onConfirm: (overridePreviousDecision: boolean) => void;
    isOverridingRejection?: boolean;
  }> = ({ isOpen, onClose, onConfirm, isOverridingRejection }) => {
    const [confirmOverride, setConfirmOverride] = useState(false);
  
    return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader borderBottomWidth="1px">Confirm Approval</ModalHeader>
          <ModalBody py={8}>
            <Text>Are you sure you want to approve this booking?</Text>
  
            {isOverridingRejection && (
              <Box mt={4}>
                <Flex align="center">
                  <Checkbox
                    isChecked={confirmOverride}
                    onChange={(e) => setConfirmOverride(e.target.checked)}
                    mr={3}
                  />
                  <Text color="red.600">
                    I understand this will override the previous rejection
                    decision
                  </Text>
                </Flex>
              </Box>
            )}
          </ModalBody>
          <ModalFooter borderTopWidth="1px">
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="green"
              onClick={() => onConfirm(confirmOverride)}
              isDisabled={isOverridingRejection && !confirmOverride}
            >
              Confirm Approval
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };