import { Alert, AlertIcon } from '@chakra-ui/react';

type OverThresholdAlertProps = {
  deliveryVehicleId: string;
};

export const OverThresholdAlert = ({ deliveryVehicleId }: OverThresholdAlertProps) => {
  const message =
    deliveryVehicleId === 'BIKE'
      ? 'Bike delivery options are unavailable due to stops outside of London.'
      : 'Pre 10AM and Priority delivery options are unavailable due to stops outside of London.';

  return (
    <Alert my={2} status="warning" borderRadius="md" data-cy={`${deliveryVehicleId.toLowerCase()}-delivery-warning`}>
      <AlertIcon />
      {message}
    </Alert>
  );
}; 