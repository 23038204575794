import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    List,
    ListItem
} from '@chakra-ui/react';

export const BookingApprovalAlert = () => {
  return (
    <Alert variant="left-accent" borderRadius="md" flexDirection="column">
      <AlertIcon />
      <AlertTitle>Booking Approval Required</AlertTitle>
      <AlertDescription padding={2}>
        <List spacing={1} listStyleType="disc">
          <ListItem>
            Your organization requires booking approval after creation.
          </ListItem>
          <ListItem>
            Your team's assigned approver(s) will be notified immediately via
            email
          </ListItem>
          <ListItem>
            Fulfilment will be held until approval is granted
          </ListItem>
          <ListItem>You'll be notified once the approval is complete</ListItem>
        </List>
      </AlertDescription>
    </Alert>
  );
};
