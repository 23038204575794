import { Box, Divider, Flex, Heading, useDisclosure } from '@chakra-ui/react';
import {
  useApproveBooking,
  useGetBookingApproval,
  useRejectBooking,
} from '@tradeaze/frontend/hooks';
import {
  ActionButtonsWrapper,
  ApprovalHistoryTimeline,
  BookingApprovalStatusBanner,
  brandSecondaryColor,
  OrderSummary,
  PageError,
  RejectionConfirmationModal,
  PageLoading,
  TradeazeLogo,
  ApprovalConfirmationModal,
} from '@tradeaze/frontend/ui-web';
import { getApiErrorMessage } from '@tradeaze/shared/utils';
import toast from 'react-hot-toast';
import { useParams, useSearchParams } from 'react-router-dom';

export const BookingApprovalPage = () => {
  const { approvalId } = useParams();
  const [searchParams] = useSearchParams();

  const userId = searchParams.get('userId');

  const rejectionDisclosure = useDisclosure();
  const approvalDisclosure = useDisclosure();

  const approveMutation = useApproveBooking();
  const rejectMutation = useRejectBooking();

  const getBookingApprovalQuery = useGetBookingApproval(approvalId, {
    retry: false,
  });

  const bookingApproval = getBookingApprovalQuery.data;

  const is404 = getBookingApprovalQuery.error?.status === 404;

  const currentStatus =
    bookingApproval?.latestApproval?.status || bookingApproval?.status;
  const showStatusBanner = currentStatus !== 'PENDING';

  const showApprovalAction =
    currentStatus === 'PENDING' || currentStatus === 'REJECTED';

  const showRejectionAction = currentStatus === 'PENDING';

  const isOverridingRejection = currentStatus === 'REJECTED';

  const handleApprove = async (overridePreviousDecision: boolean) => {
    if (!approvalId) return;
    try {
      await approveMutation.mutateAsync({
        approvalId,
        body: {
          actionedBy: userId,
          createNewDecision: overridePreviousDecision,
        },
      });
      approvalDisclosure.onClose();
      toast.success('Booking Approved');
    } catch (error) {
      const errorMessage = getApiErrorMessage(error);
      toast.error(errorMessage);
    }
  };

  const handleReject = async (rejectionReason: string) => {
    if (!approvalId) return;
    try {
      await rejectMutation.mutateAsync({
        approvalId,
        body: {
          actionedBy: userId,
          comments: rejectionReason,
        },
      });
      toast.success('Booking Rejected');
      rejectionDisclosure.onClose();
    } catch (error) {
      const errorMessage = getApiErrorMessage(error);
      toast.error(errorMessage);
    }
  };

  if (!userId) {
    return <PageError text="User ID not found" color={brandSecondaryColor} />;
  }

  if (getBookingApprovalQuery.isLoading) {
    return <PageLoading />;
  }

  if (getBookingApprovalQuery.isError && !is404) {
    return <PageError />;
  }

  if (is404 || !bookingApproval?.order) {
    return (
      <PageError
        text="This booking could not be found"
        color={brandSecondaryColor}
      />
    );
  }

  return (
    <>
      <Box
        borderRadius={{ base: 0, md: 'lg' }}
        borderColor={{ base: 'transparent', md: 'gray.300' }}
        p={{ base: 4, md: 10 }}
        boxShadow={{ base: 'none', md: 'lg' }}
        maxWidth={'container.md'}
        mx="auto"
      >
        <Flex justify="space-between" align="center">
          <TradeazeLogo width="80px" color={brandSecondaryColor} />
          <Box textAlign="right">
            <Heading size="md" mb={2}>
              Booking Approval
            </Heading>
            <Heading size="sm">
              for {bookingApproval.order.Merchant?.merchantName}
            </Heading>
          </Box>
        </Flex>

        <Divider my={6} borderColor="gray.300" />

        {showStatusBanner &&
          currentStatus &&
          bookingApproval?.latestApproval && (
            <BookingApprovalStatusBanner
              bookingApproval={bookingApproval.latestApproval}
              isAdmin={false}
            />
          )}

        {bookingApproval.linkedApprovals &&
          bookingApproval.linkedApprovals.length > 1 && (
            <>
              <ApprovalHistoryTimeline
                bookingApprovals={bookingApproval.linkedApprovals}
              />
              <Divider my={6} borderColor="gray.300" />
            </>
          )}

        <OrderSummary
          order={bookingApproval.order}
          showBackButton={false}
          proofOfDeliveryBucketname={null}
          isAdmin={false}
          columns={[1]}
          showApprovalStatusBanner={false}
        />
      </Box>

      <ActionButtonsWrapper
        showRejectionAction={showRejectionAction}
        showApprovalAction={showApprovalAction}
        onReject={rejectionDisclosure.onOpen}
        onApprove={approvalDisclosure.onOpen}
      />

      <RejectionConfirmationModal
        isOpen={rejectionDisclosure.isOpen}
        onClose={rejectionDisclosure.onClose}
        onConfirm={handleReject}
      />

      <ApprovalConfirmationModal
        isOpen={approvalDisclosure.isOpen}
        onClose={approvalDisclosure.onClose}
        onConfirm={handleApprove}
        isOverridingRejection={isOverridingRejection}
      />
    </>
  );
};
