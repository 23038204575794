import { z } from 'zod';
import { OrdersQueryParamsSchema } from '../../../models';

export const DownloadMerchantOrdersCsvQuerySchema = OrdersQueryParamsSchema.omit({
  startIndex: true,
  limit: true,
  merchantId: true,
  merchantIds: true,
})

export const DownloadMerchantOrdersCsvResponseSchema = z.object({
  url: z.string(),
});

export type DownloadMerchantOrdersCsvQuery = z.infer<
  typeof DownloadMerchantOrdersCsvQuerySchema
>;

export type DownloadMerchantOrdersCsvResponse = z.infer<
  typeof DownloadMerchantOrdersCsvResponseSchema
>;

export type MerchantOrdersCsvColumns = {
  'Merchant Name': string
  'Order Type': string
  'Order ID': string
  'Delivery Price': number
  'Service Charge': number
  'Items Value': number
  'Cancellation Fee': number
  'Order Status': string
  'Purchase Order Reference': string
  'Pickup Postcodes': string,
  'Dropoff Postcodes': string,
  'Delivery Vehicle': string
  'Delivery Option': string
  'Delivery Window Start': string
  'Delivery Window End': string
  'Booked By': string
  'Created At': string
  'Completed At': string
  'Cancelled At': string
  'Cancellation Reason': string,
  'Collection Reference': string
}