import { z } from 'zod';
import { BookingApprovalSchema } from '../../models';
import { processStringBoolean } from '../../utils';

export const ApproveBookingApprovalBodySchema = z.object({
  comments: z.string().nullish(),
  actionedBy: z.string().min(1, 'Actioned by is required').nullable(),
  createNewDecision: z.preprocess(
    (val) => processStringBoolean(val),
    z.boolean().optional(),
  ),
});

export const ApproveBookingApprovalResponseSchema = BookingApprovalSchema;

export type ApproveBookingApprovalBody = z.infer<typeof ApproveBookingApprovalBodySchema>;
export type ApproveBookingApprovalResponse = z.infer<typeof ApproveBookingApprovalResponseSchema>; 