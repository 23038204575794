import { z } from "zod";

export const BookingUserSchema = z.object({
  id: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  contactNumber: z.string().nullable(),
});

export const CreateBookingUserSchema = BookingUserSchema.omit({
  createdAt: true,
  updatedAt: true,
});

export type BookingUser = z.infer<typeof BookingUserSchema>;
export type CreateBookingUser = z.infer<typeof CreateBookingUserSchema>;