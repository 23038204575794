import { z } from 'zod';
import { InvoiceSchema } from './invoice-schema';
import { MerchantProfileSchema } from './merchant-schema';
import { OrderSchema } from './order-schema';
import { RiderIdentitySchema, RiderAccountSchema } from './rider-schema';
import {
  CommonDeliveryStopSchema,
  DeliveryStopTypeSchema,
} from './delivery-stop-schema';
import { FleetVehicleSchema } from './fleet-vehicle-schema';
import { ClockInRecordSchema } from './clock-in-schema';
import { DeliveryItemSchema } from './delivery-item-schema';
import { EtaStatusSchema } from './eta-status-schema';
import { DeliverySchema } from './delivery-schema';
import { DeliveryStatusSchema } from './delivery-status-schema';
import {
  DropoffDeliveryStopSchema,
  PickupDeliveryStopSchema,
} from './delivery-stop-schema';
import { GigJobSchema } from './gig-job-schema';
import { ExternalJobSchema } from './external-job-schema';
import { ExternalCourierSchema } from './external-courier-schema';
import { RiderDocumentTypeSchema } from './rider-document-schema';
import { RiderDocumentSchema } from './rider-document-schema';
import { GigJobBoardSchema } from './gig-job-board-schema';
import { RiderBankDetailsSchema } from './rider-bank-details-schema';
import { WorkTypeSchema } from './work-type-schema';
import {
  BookingApprovalSchema,
  BookingApprovalStatusSchema,
} from './booking-approval-schema';
import { BookingUserSchema } from './booking-user-schema';

/**
 * Store hydrated schemas in this file to avoid circular dependencies
 */
export const HydratedDeliverySchema = DeliverySchema.extend({
  rider: RiderIdentitySchema.omit({
    riderLocation: true,
  }).nullish(),
  pickup: PickupDeliveryStopSchema.extend({
    etaStart: z.string().nullish(),
    etaEnd: z.string().nullish(),
  }),
  dropOff: DropoffDeliveryStopSchema.extend({
    etaStart: z.string().nullish(),
    etaEnd: z.string().nullish(),
  }),
  deliveryItems: z.array(DeliveryItemSchema),
  order: OrderSchema,
  merchant: MerchantProfileSchema.pick({
    merchantId: true,
    merchantName: true,
    contactNumber: true,
    invoicingName: true,
    invoicingEmail: true,
  })
    .extend({
      contactNumber: z.string().nullish(),
    })
    .nullish(),
  etaStatus: EtaStatusSchema.nullish(),
  gigJob: GigJobSchema.nullish(),
  externalJob: ExternalJobSchema.nullish(),
  externalCourier: ExternalCourierSchema.nullish(),
});

export const HydratedDeliveryStopSchema = CommonDeliveryStopSchema.extend({
  deliveryStatus: DeliveryStatusSchema.optional(),
  merchantName: z.string().nullish(),
  merchantOrderReference: z.string().nullish(),
  heavySideItems: z.boolean().nullish(),
  bookedBy: z.string().nullish(),
  delivery: HydratedDeliverySchema.omit({
    pickup: true,
    dropOff: true,
    order: true,
  }).nullish(),
  deliveryItems: z.array(DeliveryItemSchema).nullish(),
  linkedDeliveryStops: z.array(CommonDeliveryStopSchema).nullish(),
  proofSignatures: z
    .array(
      z.object({
        key: z.string().nullable(),
        url: z.string(),
      }),
    )
    .nullish(),
  proofImages: z
    .array(
      z.object({
        key: z.string().nullable(),
        url: z.string(),
      }),
    )
    .nullish(),
  etaStart: z.string().nullish(),
  etaEnd: z.string().nullish(),
  etaStatus: EtaStatusSchema.nullish(),
  status: DeliveryStatusSchema.optional(),
  rider: RiderIdentitySchema.nullish(),
});

export const HydratedPickupStopSchema = HydratedDeliveryStopSchema.extend({
  type: z.literal(DeliveryStopTypeSchema.Values.PICK_UP),
});

export const HydratedDropoffStopSchema = HydratedDeliveryStopSchema.extend({
  type: z.literal(DeliveryStopTypeSchema.Values.DROP_OFF),
});

export const HydratedOrderSchema = OrderSchema.extend({
  Merchant: MerchantProfileSchema.pick({
    merchantName: true,
    invoicingName: true,
    invoicingEmail: true,
  }).nullish(),
  deliveries: z.array(HydratedDeliverySchema.omit({ order: true })),
  deliveryStops: z.array(HydratedDeliveryStopSchema).optional(),
  workType: WorkTypeSchema.nullish(),
  bookingApprovals: z
    .array(
      BookingApprovalSchema.extend({
        actionedUser: BookingUserSchema.nullish(),
      }),
    )
    .nullish(),
  bookingApprovers: z.array(BookingUserSchema).nullish(),
  bookingApprovalStatus: BookingApprovalStatusSchema.nullish(),
});

export const HydratedBookingApprovalSchema = BookingApprovalSchema.extend({
  order: HydratedOrderSchema.nullish(),
  actionedUser: BookingUserSchema.pick({
    id: true,
    firstName: true,
    lastName: true,
    email: true,
    contactNumber: true,
  }).nullish(),
});

export const TrackingDeliverySchema = HydratedDeliverySchema.extend({
  etaStatus: EtaStatusSchema.nullish(),
});

export const HydratedInvoiceSchema = InvoiceSchema.extend({
  Merchant: MerchantProfileSchema.pick({
    merchantName: true,
  }).nullish(),
  Order: OrderSchema.nullish(),
});

export const HydratedClockInSchema = ClockInRecordSchema.omit({
  workType: true, // omitted this field as it is also present in RiderIdentitySchema
}).extend({
  rider: RiderIdentitySchema.nullable(),
  fleetVehicle: FleetVehicleSchema.nullable(),
});

export const HydratedGigJobSchema = GigJobSchema.extend({
  rider: RiderIdentitySchema.nullish(),
  deliveries: z.array(
    DeliverySchema.extend({
      pickup: PickupDeliveryStopSchema,
      dropOff: DropoffDeliveryStopSchema,
      deliveryItems: z.array(DeliveryItemSchema),
    }),
  ),
  gigJobBoard: z.array(GigJobBoardSchema).nullish(),
});

export const HydratedFleetVehicleSchema = FleetVehicleSchema.extend({
  owner: RiderIdentitySchema.nullish(),
});

export const HydratedRiderSchema = RiderAccountSchema.extend({
  fleetVehicles: z.array(FleetVehicleSchema).nullish(),
  bankDetails: RiderBankDetailsSchema.nullish(),
});

export const HydratedRiderDocumentSchema = RiderDocumentSchema.extend({
  name: RiderDocumentTypeSchema.shape.name,
  description: RiderDocumentTypeSchema.shape.description,
  url: z.string().nullish(),
});

export type HydratedOrder = z.infer<typeof HydratedOrderSchema>;
export type HydratedDeliveryStop = z.infer<typeof HydratedDeliveryStopSchema>;
export type HydratedPickupStop = z.infer<typeof HydratedPickupStopSchema>;
export type HydratedDropoffStop = z.infer<typeof HydratedDropoffStopSchema>;
export type HydratedInvoice = z.infer<typeof HydratedInvoiceSchema>;
export type HydratedClockIn = z.infer<typeof HydratedClockInSchema>;
export type TrackingDelivery = z.infer<typeof TrackingDeliverySchema>;
export type HydratedDelivery = z.infer<typeof HydratedDeliverySchema>;
export type PaginatedOrderResponse = {
  orders: HydratedOrder[];
  nextPageIndex: number | null;
};
export type PaginatedDeliveryResponse = {
  deliveries: HydratedDelivery[];
  nextPageIndex: number | null;
};
export type HydratedGigJob = z.infer<typeof HydratedGigJobSchema>;
export type HydratedFleetVehicle = z.infer<typeof HydratedFleetVehicleSchema>;
export type HydratedRider = z.infer<typeof HydratedRiderSchema>;
export type HydratedRiderDocument = z.infer<typeof HydratedRiderDocumentSchema>;
export type HydratedBookingApproval = z.infer<
  typeof HydratedBookingApprovalSchema
>;
