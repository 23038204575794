import {
    Box,
    Flex,
    Text
} from '@chakra-ui/react';
import { HydratedBookingApproval } from '@tradeaze-packages/schemas';
import React from 'react';
import { formatBookingUserName } from './utils/formatBookingUserName';

const getStatusColors = (status: string) => {
  switch (status.toUpperCase()) {
    case 'REJECTED':
      return {
        dot: 'red.500',
        text: 'red.600',
        border: 'red.500'
      };
    case 'APPROVED':
      return {
        dot: 'green.500',
        text: 'green.600',
        border: 'green.500'
      };
    case 'PENDING':
      return {
        dot: 'yellow.400',
        text: 'yellow.600',
        border: 'yellow.400'
      };
    default:
      return {
        dot: 'gray.400',
        text: 'gray.600',
        border: 'gray.400'
      };
  }
};

export const ApprovalHistoryTimeline: React.FC<{
    bookingApprovals: HydratedBookingApproval[];
  }> = ({ bookingApprovals }) => {
  
    return (
      <Box mt={6}>
        <Text fontWeight="semibold" mb={4}>
          Approval History
        </Text>
        <Box position="relative">
          {bookingApprovals.map((approval, index) => (
            <Flex key={approval.id} mb={4} position="relative">
              {/* Timeline connector */}
              {index !== bookingApprovals.length - 1 && (
                <Box
                  position="absolute"
                  left="3px"
                  top="16px"
                  bottom="-26px"
                  width="2px"
                  bg="gray.200"
                />
              )}
              {/* Status dot */}
              <Box
                w="8px"
                h="8px"
                mt={2}
                mr={4}
                borderRadius="full"
                bg={getStatusColors(approval.status).dot}
                position="relative"
                zIndex={1}
              />
              <Box flex={1}>
                <Text
                  fontWeight="medium"
                  color={getStatusColors(approval.status).text}
                >
                  {approval.status.charAt(0) +
                    approval.status.slice(1).toLowerCase()}
                </Text>
                {approval.actionedUser && (
                  <Text fontSize="sm" color="gray.600">
                    by {formatBookingUserName(approval.actionedUser)} (
                    {approval.actionedUser?.email})
                  </Text>
                )}
                {approval.actionedAt && (
                  <Text fontSize="sm" color="gray.500">
                    {new Date(approval.actionedAt).toLocaleDateString('en-US', {
                      day: 'numeric',
                      month: 'short',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </Text>
                )}
                {approval.comments && (
                  <Text
                    fontSize="sm"
                    mt={2}
                    p={3}
                    bg="gray.50"
                    borderRadius="md"
                    borderLeft="3px solid"
                    borderColor={getStatusColors(approval.status).border}
                  >
                    {approval.comments}
                  </Text>
                )}
              </Box>
            </Flex>
          ))}
        </Box>
      </Box>
    );
  };