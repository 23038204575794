import { HydratedBookingApprovalSchema } from '../../models';
import { z } from 'zod';

export const GetBookingApprovalResponseSchema = HydratedBookingApprovalSchema.extend({
  latestApproval: HydratedBookingApprovalSchema.nullish(),
  linkedApprovals: z.array(HydratedBookingApprovalSchema).nullish(),
});

export type GetBookingApprovalResponse = z.infer<
  typeof GetBookingApprovalResponseSchema
>;
