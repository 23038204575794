import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Textarea
} from '@chakra-ui/react';
import React, { useState } from 'react';  

export const RejectionConfirmationModal: React.FC<{
    isOpen: boolean;
    onClose: () => void;
    onConfirm: (rejectionReason: string) => Promise<void>;
  }> = ({ isOpen, onClose, onConfirm }) => {
    const [rejectionReason, setRejectionReason] = useState('');
  
    const handleChangeRejectionReason = (
      e: React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
      setRejectionReason(e.target.value);
    };
  
    const handleConfirm = () => {
      onConfirm(rejectionReason);
      onClose();
    };
  
    return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reason for Rejection</ModalHeader>
          <ModalBody py={8}>
            <Textarea
              placeholder="Please provide the reason for rejection..."
              value={rejectionReason}
              onChange={handleChangeRejectionReason}
            />
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={handleConfirm}>
              Confirm Rejection
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };
  