export const vehicleOptions = [
  {
    type: 'Van (LWB)' as const,
    capacity: {
      length: 3.3,
      weight: 1000,
    },
    price: 300,
    description: 'A long-wheelbase van suitable for most deliveries.',
  },
  {
    type: 'Van (X-LWB)' as const,
    capacity: {
      length: 4.2,
      weight: 1200,
    },
    price: 350,
    description: 'An extra long-wheelbase van suitable for deliveries of longer lengths.',
  },
  {
    type: 'Luton' as const,
    capacity: {
      length: 4,
      weight: 1000,
    },
    price: 375,
    description: 'Large box van perfect for furniture and bulky items.',
  },
  {
    type: 'Flatbed' as const,
    capacity: {
      length: 7,
      weight: 1200,
    },
    price: 400,
    description: 'Open bed truck ideal for construction materials.',
  },
] as const;

export type VehicleType = (typeof vehicleOptions)[number]['type'];
