export const getMerchantUrlFromCurrentUrl = () => {
  // if current url includes local host, set to locahost 4200
  // if current url includes dev, stage, test, set to [env].tradeaze.app
  // if current url includes prod, set to https://tradeaze.app

  const url = new URL(window.location.href);
  const host = url.host;

  if (
    host.includes('localhost') ||
    host.includes('127.0.0.1') ||
    host.includes('0.0.0.0')
  ) {
    return 'http://localhost:4200';
  }

  if (host.includes('dev')) {
    return 'https://dev.tradeaze.app';
  }

  if (host.includes('stage')) {
    return 'https://stage.tradeaze.app';
  }

  if (host.includes('test')) {
    return 'https://test.tradeaze.app';
  }

  return 'https://tradeaze.app';
};
