import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Alert,
  FormErrorMessage,
} from '@chakra-ui/react';
import { UseFormRegister, FieldErrors } from 'react-hook-form';
import { ManVanFormData } from './types';

interface BookingFormProps {
  register: UseFormRegister<ManVanFormData>;
  errors: FieldErrors<ManVanFormData>;
  isSubmitting: boolean;
  isSuccess: boolean;
  minDate: string;
}

export const ManVanBookingForm = ({
  register,
  errors,
  isSubmitting,
  isSuccess,
  minDate,
}: BookingFormProps) => {
  return (
    <Stack spacing={6}>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={{ base: 4, md: 8 }}
      >
        <FormControl isRequired isInvalid={!!errors.date}>
          <FormLabel>Date Required</FormLabel>
          <Input type="date" {...register('date')} min={minDate} />
          <FormErrorMessage>{errors.date?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.bookedBy}>
          <FormLabel>Booked By</FormLabel>
          <Input {...register('bookedBy')} placeholder="Enter your name" />
          <FormErrorMessage>{errors.bookedBy?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={!!errors.contactNumber}>
          <FormLabel>Contact Number</FormLabel>
          <Input
            {...register('contactNumber')}
            type="tel"
            placeholder="Enter your phone number"
          />
          <FormErrorMessage>{errors.contactNumber?.message}</FormErrorMessage>
        </FormControl>
      </Stack>

      <Button
        type="submit"
        size="lg"
        isLoading={isSubmitting}
        loadingText="Submitting"
        my={4}
      >
        Submit Enquiry
      </Button>

      {isSuccess && (
        <Alert
          status="success"
          variant="subtle"
          borderRadius="md"
          animation="none"
        >
          ManVan Hire Enquiry Submitted! We will be in touch shortly.
        </Alert>
      )}
    </Stack>
  );
};
