import { BookingApprovalStatus } from '@tradeaze-packages/schemas';
import { BookingApprovalStatusTag } from '../../../booking-appovals';
import { Box, Divider } from '@chakra-ui/react';

export const CardBookingApprovalTag = ({
  bookingApprovalStatus,
}: {
  bookingApprovalStatus: BookingApprovalStatus;
}) => {
  const showWhenStatus = ['PENDING', 'REJECTED'];

  if (!showWhenStatus.includes(bookingApprovalStatus)) {
    return null;
  }

  return (
    <>
      <Divider my={2} />
      <Box py={1}>
        <BookingApprovalStatusTag status={bookingApprovalStatus} />
      </Box>
    </>
  );
};
