import { Box, Button, HStack } from '@chakra-ui/react';
import React from 'react';

export const DesktopActionsContainer: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <Box
      display={{ base: 'none', md: 'block' }}
      position="fixed"
      bottom={8}
      right={8}
      bg="white"
      p={4}
      borderRadius="md"
      boxShadow="lg"
      zIndex={10}
    >
      <HStack>{children}</HStack>
    </Box>
  );
};

export const MobileActionsContainer: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <Box
      display={{ base: 'block', md: 'none' }}
      position="fixed"
      bottom={0}
      left={0}
      right={0}
      bg="white"
      borderTopWidth="1px"
      borderTopColor="gray.200"
      p={4}
      zIndex={10}
    >
      <HStack spacing={4} justify="stretch" width="100%">
        {children}
      </HStack>
    </Box>
  );
};

export const ActionButtons: React.FC<{
  showRejectionAction?: boolean;
  showApprovalAction?: boolean;
  onReject: () => void;
  onApprove: () => void;
  fullWidth?: boolean;
}> = ({
  showRejectionAction = true,
  showApprovalAction = true,
  onReject,
  onApprove,
  fullWidth,
}) => {
  return (
    <>
      {showRejectionAction && (
        <Button
          colorScheme="red"
          onClick={onReject}
          w={fullWidth ? '100%' : 'auto'}
        >
          Reject
        </Button>
      )}
      {showApprovalAction && (
        <Button
          colorScheme="green"
          onClick={onApprove}
          w={fullWidth ? '100%' : 'auto'}
        >
          Approve
        </Button>
      )}
    </>
  );
};

export const ActionButtonsWrapper: React.FC<{
  showRejectionAction: boolean;
  showApprovalAction: boolean;
  onReject: () => void;
  onApprove: () => void;
}> = (props) => {
  if (!props.showRejectionAction && !props.showApprovalAction) return null;

  return (
    <>
      <DesktopActionsContainer>
        <ActionButtons {...props} />
      </DesktopActionsContainer>

      <MobileActionsContainer>
        <ActionButtons {...props} fullWidth />
      </MobileActionsContainer>
    </>
  );
};
