import { z } from "zod";

export const BookingApprovalStatusSchema = z.enum(['PENDING', 'APPROVED', 'REJECTED']);

export const BookingApprovalSchema = z.object({
  id: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  orderId: z.string(),
  status: BookingApprovalStatusSchema,
  actionedBy: z.string().nullable(),
  actionedAt: z.string().nullable(),
  comments: z.string().nullable(),
});

export const CreateBookingApprovalSchema = BookingApprovalSchema.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
  actionedBy: true,
  actionedAt: true,
  comments: true,
});

export type BookingApprovalStatus = z.infer<typeof BookingApprovalStatusSchema>;
export type BookingApproval = z.infer<typeof BookingApprovalSchema>;
export type CreateBookingApproval = z.infer<typeof CreateBookingApprovalSchema>;