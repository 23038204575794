import { z } from 'zod';
import {
  RegionIdEnumSchema,
  OrderTypeSchema,
} from '../../../models';
import { GroupedOptionsByVehicleSchema } from '../../../models/delivery-option-schema';
import { processStringBoolean } from '../../../utils';

export const GetDeliveryOptionsQuerySchema = z.object({
  regionId: RegionIdEnumSchema,
  date: z.string(),
  isAdmin: z.preprocess(processStringBoolean, z.boolean()),
  hidePriorityDelivery: z.preprocess(processStringBoolean, z.boolean()),
  isAnyStopOverThreshold: z.preprocess(processStringBoolean, z.boolean()),
  orderType: OrderTypeSchema.optional(),
});

export const GetDeliveryOptionsResponseSchema = GroupedOptionsByVehicleSchema;

export type GetDeliveryOptionsQuery = z.infer<
  typeof GetDeliveryOptionsQuerySchema
>;
export type GetDeliveryOptionsResponse = z.infer<
  typeof GetDeliveryOptionsResponseSchema
>;
