import {
  extendZodWithOpenApi,
  OpenAPIRegistry,
} from '@asteasolutions/zod-to-openapi';
import { z } from 'zod';
import {
  ApproveBookingApprovalBodySchema,
  ApproveBookingApprovalResponseSchema,
  GetBookingApprovalResponseSchema,
  RejectBookingApprovalBodySchema,
  RejectBookingApprovalResponseSchema,
} from '../../booking-approval-service';
import { commonErrorResponses } from './common';

extendZodWithOpenApi(z);

const registry = new OpenAPIRegistry();

const basePath = '/booking-approvals';

/**
 * POST /:approvalId/approve
 */
registry.registerPath({
  path: `${basePath}/{approvalId}/approve`,
  method: 'post',
  operationId: 'approveBookingApproval',
  request: {
    params: z.object({
      approvalId: z.string(),
    }),
    body: {
      content: {
        'application/json': {
          schema: ApproveBookingApprovalBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Booking approval approved.',
      content: {
        'application/json': {
          schema: ApproveBookingApprovalResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /:approvalId/reject
 */
registry.registerPath({
  path: `${basePath}/{approvalId}/reject`,
  method: 'post',
  operationId: 'rejectBookingApproval',
  request: {
    params: z.object({
      approvalId: z.string(),
    }),
    body: {
      content: {
        'application/json': {
          schema: RejectBookingApprovalBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Booking approval rejected.',
      content: {
        'application/json': {
          schema: RejectBookingApprovalResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /admin/:approvalId/approve
 */
registry.registerPath({
  path: `${basePath}/admin/{approvalId}/approve`,
  method: 'post',
  operationId: 'approveBookingApprovalAdmin',
  request: {
    params: z.object({
      approvalId: z.string(),
    }),
    body: {
      content: {
        'application/json': {
          schema: ApproveBookingApprovalBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Booking approval approved.',
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /admin/:approvalId/reject
 */
registry.registerPath({
  path: `${basePath}/admin/{approvalId}/reject`,
  method: 'post', 
  operationId: 'rejectBookingApprovalAdmin',
  request: {
    params: z.object({
      approvalId: z.string(),
    }),
    body: {
      content: {
        'application/json': {
          schema: RejectBookingApprovalBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Booking approval rejected.',
      content: {
        'application/json': {
          schema: RejectBookingApprovalResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /:approvalId
 */
registry.registerPath({
  path: `${basePath}/{approvalId}`,
  method: 'get',
  operationId: 'getBookingApproval',
  request: {
    params: z.object({
      approvalId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Get booking approval.',
      content: {
        'application/json': {
          schema: GetBookingApprovalResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  }
});


export const bookingApprovalApiRegistry = registry;